import { useEffect, useState } from "react";
import { LandingWidget } from "./widgets";
import { Link } from "react-router-dom";
import { allRoutes } from "../../util/routes";
import appStoreButton from "../../assets/app-store-button.svg";
import landingImage0 from "../../assets/hero-1.jpg";
import landingImage1 from "../../assets/hero-2.jpg";
import landingImage2 from "../../assets/hero-3.jpg";
import landingImage3 from "../../assets/hero-4.jpg";
import landingImage4 from "../../assets/hero-5.jpg";
import landingImage5 from "../../assets/hero-6.jpg";
import landingImage6 from "../../assets/hero-7.jpg";
import landingImage7 from "../../assets/hero-8.jpg";
import landingImage8 from "../../assets/hero-9.jpg";
import logoNeonWhite from "../../assets/neon-logos/candid-neon-white.png";

function preloadImages(images: string[]) {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
}

const backgroundImages = [
  landingImage0,
  landingImage1,
  landingImage2,
  landingImage3,
  landingImage4,
  landingImage5,
  landingImage6,
  landingImage7,
  landingImage8,
];
preloadImages(backgroundImages);

export function LandingHero(): JSX.Element {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    let loadedCount = 0;
    const imageElements = backgroundImages.map((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === backgroundImages.length) {
          setImagesLoaded(true);
        }
      };
      return img;
    });

    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 3000);

    return () => {
      clearInterval(interval);
      imageElements.forEach((img) => {
        img.onload = null;
      });
    };
  }, []);

  const backgroundStyle = imagesLoaded
    ? {
        backgroundImage: `url(${backgroundImages[currentBgIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center 30%",
        transition: isMobile ? "" : "background-image 1.5s ease-in-out",
      }
    : {
        backgroundColor: "#333", // Placeholder color while loading
      };

  return (
    <div
      style={{
        ...backgroundStyle,
        height: "100vh",
        position: "relative",
      }}
      className="z-10 items-center"
    >
      <div className="flex absolute w-full max-w-[80vw] justify-start z-20 pt-safe">
        <img src={logoNeonWhite} className="w-40 md:mx-[15vw] mx-[10vw] mt-5" alt="Logo" />
      </div>
      <div className="lg:flex md:flex md:justify-around md:items-center px-[10vw] h-full z-50 relative">
        <div className="lg:w-auto md:w-auto w-full md:max-w-[75%] lg:max-w-[50vw]">
          <LandingWidget
            topText="THE FIRST ONLINE ENM NETWORK"
            cta="JOIN CANDID"
            text="The premier discovery & connection platform for the ethically non-monogamous."
            btn={
              <div className="flex">
                <a className="mr-5" href="https://apps.apple.com/us/app/candid-enm/id6466639507">
                  <img src={appStoreButton} alt="App Store" />
                </a>
                <Link to={allRoutes.login.buildPath({})}>
                  <p className="bg-dark-sunset text-white font-bold py-2 px-4 rounded-full transition duration-300 drop-shadow-xl">
                    JOIN NOW
                  </p>
                </Link>
              </div>
            }
          />
        </div>
        <div className="lg:w-auto md:w-auto w-full md:max-w-[75%] lg:max-w-[50vw]">
          <LandingWidget
            topText="Returning Users"
            cta="LOGIN"
            text="If you're already an approved Candid member, login below."
            btn={
              <Link to={allRoutes.login.buildPath({})}>
                <p className="bg-dark-sunset max-w-20 text-white font-bold py-2 px-4 rounded-full transition duration-300 drop-shadow-xl">
                  LOGIN
                </p>
              </Link>
            }
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 w-full h-full z-1"
        style={{
          backgroundImage: "radial-gradient(at bottom left, rgba(0, 0, 0, 1) 0%, transparent 100%)",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
}

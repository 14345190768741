import { Container } from "../../widgets/container";
import { BuildWidget } from "./widgets";

export function LandingBuilding(): JSX.Element {
  return (
    <Container size="md">
      <div className="lg:flex lg:justify-evenly md:flex md:justify-evenly bg-candid-gray-500">
        <div className="lg:w-auto md:w-auto w-full md:max-w-[75%] lg:max-w-[50vw]">
          <BuildWidget
            cta="Not ready to join? subscribe for more updates."
            text="We're actively building Candid and would love to keep you in the loop. Subscribe today to keep up to date."
            btn={
              <a
                className="bg-dark-sunset max-w-40 text-white font-bold py-2 px-4 rounded-full transition duration-300 drop-shadow-xl text-center"
                href="https://bio.us21.list-manage.com/subscribe/post?u=c4877b294b88d8f8fe4a43ce9&amp;id=66029bed2d&amp;f_id=003ce5e1f0"
              >
                JOIN EMAIL LIST
              </a>
            }
          />
        </div>
      </div>
    </Container>
  );
}

import React from "react";
import {
  Feature_Flags_Enum,
  L_Discovery_Item,
  L_Search_Result,
  useGetExtendedFriendsListQuery,
  useMyRelationshipsShortV410Query,
  L_Discovery_User,
} from "shared/dist/__generated__/components";
import { filterNulls } from "shared/dist/util";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";

function mergeExtendedFriendsAndRelationships(
  extendedFriends: L_Search_Result[],
  relationshipItems: L_Search_Result[],
  includeRelationships: boolean
): L_Discovery_Item[] {
  const mergedExtendedFriends: L_Discovery_Item[] = extendedFriends
    .filter(
      (friend): friend is L_Search_Result & { slug: string } =>
        friend.slug != null && friend.slug !== ""
    )
    .map(
      (friend): L_Discovery_Item => ({
        __typename: "L_Discovery_Item",
        id: friend.user_id,
        user: {
          __typename: "L_Discovery_User",
          age: 0,
          distance_m: 0,
          gender_id: "",
          location_description: null,
          user_id: friend.user_id,
          user_screen_name: friend.screen_name,
          user_slug: friend.slug,
          verified: null,
          relationships_with_user: [],
          threads_with_user: [],
        },
        partner: null,
      })
    );

  if (!includeRelationships) return mergedExtendedFriends;

  const extendedFriendIds = new Set(mergedExtendedFriends.map((friend) => friend.id));

  const mergedRelationships: L_Discovery_Item[] = relationshipItems
    .filter(
      (item): item is L_Search_Result & { slug: string } =>
        item.slug != null && item.slug !== "" && item.screen_name != null
    )
    .map(
      (item): L_Discovery_Item => ({
        __typename: "L_Discovery_Item",
        id: item.user_id,
        user: {
          __typename: "L_Discovery_User",
          age: 0,
          distance_m: 0,
          gender_id: "",
          location_description: null,
          user_id: item.user_id,
          user_screen_name: item.screen_name,
          user_slug: item.slug,
          verified: null,
          relationships_with_user: [],
          threads_with_user: [],
        },
        partner: null,
      })
    )
    .filter((item) => !extendedFriendIds.has(item.id));

  return [...mergedExtendedFriends, ...mergedRelationships];
}

export function useExtendedFriendList({
  slug,
  userId,
  includeRelationships = false,
  filter = "",
}: {
  slug: string;
  userId?: string;
  includeRelationships: boolean;
  filter?: string;
}) {
  const extendedFriendsFeatureFlag = useIsFeatureEnabled(
    Feature_Flags_Enum.ExtendedFriendSearchV00
  );

  if (!extendedFriendsFeatureFlag) {
    return {
      data: [],
      loading: false,
      refetch: () => {},
      filter: "",
    };
  }

  const {
    data: extendedFriendsData,
    loading: extendedFriendsLoading,
    refetch: extendedFriendsRefetch,
  } = useGetExtendedFriendsListQuery({
    variables: { slug, filter },
    fetchPolicy: "cache-first",
  });

  const {
    data: relationshipData,
    loading: relationshipsLoading,
    refetch: relationshipsRefetch,
    error: relationshipsError,
  } = useMyRelationshipsShortV410Query({
    skip: !userId || !includeRelationships,
    variables: { type: "like", my_id: userId! },
  });

  const mergedData = React.useMemo(() => {
    const extendedFriends = extendedFriendsData?.l_get_users_extended_friends_list?.results ?? [];
    const relationshipItems = filterNulls(
      relationshipData?.relationships_union?.map((e) => ({
        user_id: e.snd_user_summary?.id ?? "",
        slug: e.snd_user_summary?.slug ?? "",
        screen_name: e.snd_user_summary?.screen_name ?? "",
      }))
    );

    return mergeExtendedFriendsAndRelationships(
      extendedFriends,
      relationshipItems,
      includeRelationships
    );
  }, [extendedFriendsData, relationshipData, includeRelationships, filter]);

  const loading =
    extendedFriendsLoading || (includeRelationships && userId && relationshipsLoading);

  const refetch = React.useCallback(async () => {
    const [extendedFriendsResult, relationshipsResult] = await Promise.all([
      extendedFriendsRefetch({ filter }),
      includeRelationships && userId ? relationshipsRefetch() : Promise.resolve(undefined),
    ]);

    const extendedFriends =
      extendedFriendsResult.data?.l_get_users_extended_friends_list?.results ?? [];
    const relationshipItems = filterNulls(
      relationshipsResult?.data?.relationships_union?.map((e) => ({
        user_id: e.snd_user_summary?.id ?? "",
        slug: e.snd_user_summary?.slug ?? "",
        screen_name: e.snd_user_summary?.screen_name ?? "",
      }))
    );

    const mergedResults = mergeExtendedFriendsAndRelationships(
      extendedFriends,
      relationshipItems,
      includeRelationships
    );

    return { extendedFriends, relationships: relationshipItems, mergedResults };
  }, [extendedFriendsRefetch, relationshipsRefetch, userId, includeRelationships]);

  return { data: mergedData, loading, refetch, filter };
}

/* cSpell:disable */
import { AddReaction, ReactionPills } from "../../../widgets/reactions";
import {
  News_Feed_Item_Event_440Fragment,
  News_Feed_Item_Media_440Fragment,
  News_Feed_Item_Media_Reaction_440Fragment,
  News_Feed_Item_Mention_440Fragment,
  News_Feed_Item_Message_Reaction_440Fragment,
  News_Feed_Item_Status_440Fragment,
  News_Feed_Item_Status_Reaction_440Fragment,
  News_Feed_Public_Event_440Fragment,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import { ReportType, userReportConfirm } from "../settings/safety";
import {
  faHeart,
  faLocationDot,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/pro-solid-svg-icons";

import { AvatarWithScreenName } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { DisableableLink } from "../../../widgets/disableable-link";
import { EllipsisActions } from "../../../widgets/ellipsis-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMediaItem } from "../../../widgets/lazy-media-item";
import { Link } from "react-router-dom";
import { Nf_Disc_Data_440Fragment } from "shared/dist/__generated__/components";
import React from "react";
import { RelativeDate } from "shared-web-react/dist/widgets/dates";
import { allRoutes } from "../../../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { distToLocale } from "shared-web-react/dist/util/units";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";
import { useLoadImageSync } from "shared-web-react/dist/widgets/lazy-image";
import { useMkUrl } from "shared/dist/util/env";

/* cSpell:enable */

type NewsFeedItem =
  | News_Feed_Item_Event_440Fragment
  | News_Feed_Item_Media_440Fragment
  | News_Feed_Item_Status_440Fragment
  | News_Feed_Item_Mention_440Fragment
  | News_Feed_Item_Media_Reaction_440Fragment
  | News_Feed_Item_Message_Reaction_440Fragment
  | News_Feed_Item_Status_Reaction_440Fragment
  | News_Feed_Public_Event_440Fragment;

const DisableDeepLinksContext = React.createContext(false);

function DiscoveryItemSummary({
  item,
  className,
  children,
}: React.PropsWithChildren<{
  className?: string;
  item: NewsFeedItem;
}>): JSX.Element | null {
  const genders = useGenderLookup();
  if (!("discovery_data" in item) || !item.discovery_data) return null;
  const { user, partner } = item.discovery_data;
  return (
    <span className={clsx("text-[0.5rem]")}>
      <FontAwesomeIcon icon={faHeart} /> {distToLocale({ dist: user.distance_m, units: "m" })} /{" "}
      {genders?.data?.[user.gender_id]?.name?.en_US ?? ""}{" "}
      {partner && <span>{" + " + genders?.data?.[partner.gender_id]?.name?.en_US ?? ""}</span>}
    </span>
  );
}

function DiscoveryItemWrapper({
  item,
  className,
  children,
}: React.PropsWithChildren<{
  className?: string;
  item: { discovery_data?: null | Nf_Disc_Data_440Fragment } | {};
}>): JSX.Element {
  const isDiscovery = "discovery_data" in item && item.discovery_data?.user;
  return (
    <div className={clsx("relative", className)}>
      {/* <div className={clsx("relative", isDiscovery && "border-2  border-candid-red-300", className)}> */}
      {isDiscovery && (
        <div className={clsx("flex flex-row justify-stretch items-center -mb-[8px]")}>
          <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-300")} />
          <div className="flex-0 text-xs mx-2">
            <FontAwesomeIcon icon={faHeart} className="mx-1 text-candid-red-300" />
            Candid Suggestion
          </div>
          {/* <div className={clsx("flex-1 h-[1px] w-full border border-candid-lavender-300")} /> */}
        </div>
      )}
      {children}
    </div>
  );
}

function NewsFeedItemMention({ item }: { item: News_Feed_Item_Mention_440Fragment }): JSX.Element {
  const disabled = React.useContext(DisableDeepLinksContext);
  return (
    <ItemTextBox
      content={
        <span>
          Mentioned you in{" "}
          <DisableableLink
            disabled={disabled}
            onDisabledClick={() => alert("Deep linking disabled with dummy data")}
            className="link"
            to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
              thread_id: item.thread_id,
            })}
          >
            this thread
          </DisableableLink>
          .
        </span>
      }
    />
  );
}

function NewsFeedItemMediaReaction({
  item,
}: {
  item: News_Feed_Item_Media_Reaction_440Fragment;
}): JSX.Element {
  return (
    <ItemTextBox
      content={
        <div className="flex flex-row justify-between items-center">
          <span>Reacted with {item.reaction} to your image:</span>
          <img src={item.presigned_url} className="h-16 shadow-md" />
        </div>
      }
    />
  );
}

function NewsFeedItemMessageReaction({
  item,
}: {
  item: News_Feed_Item_Message_Reaction_440Fragment;
}): JSX.Element {
  const disabled = React.useContext(DisableDeepLinksContext);
  return (
    <ItemTextBox
      showQuotes
      headline={
        <span>
          Reacted with {item.reaction} to your{" "}
          <DisableableLink
            disabled={disabled}
            onDisabledClick={() => alert("Deep linking disabled with dummy data")}
            className="link"
            to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
              thread_id: item.thread_id,
            })}
          >
            message in this thread
          </DisableableLink>
          .
        </span>
      }
      content={
        <Link
          to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
            thread_id: item.thread_id,
          })}
        >
          {item.msg_content}
        </Link>
      }
    />
  );
}

function NewsFeedItemPublicEvent({
  item,
}: {
  item: News_Feed_Public_Event_440Fragment;
}): JSX.Element {
  const slug = item.event_slug;
  const imageMediaUploadId = item.media_upload_id;
  if (!slug) return <></>;
  const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug });
  const mkUrl = useMkUrl();

  // const eventImgUrl = mkUrl(path) + (imageMediaUploadId ? `?mid=${imageMediaUploadId}` : "");
  const imageData = useLoadImageSync(mkUrl(path));
  return (
    <Link
      to={allRoutes.EVENT_FROM_SLUG.buildPath({ slug })}
      className={clsx("flex flex-row justify-stretch items-center gap-2 py-1")}
    >
      <div
        className={clsx(
          "card w-96 h-48 bg-base-100 shadow-sm shadow-black m-2",
          "w-full aspect-square bg-no-repeat bg-cover bg-center overflow-hidden"
        )}
        style={imageData.imageData ? { backgroundImage: `url(${imageData.imageData})` } : {}}
      >
        <p className="bg-gray-100 bg-opacity-80 text-primary py-1 px-2 rounded-lg font-bold shadow drop-shadow-md text-sm text-center absolute m-4">
          {DateTime.fromISO(item.start).toFormat("d")} <br />
          {DateTime.fromISO(item.start).toFormat("LLL")}
        </p>
        <div
          className={clsx(
            "flex flex-col items-start justify-center",
            "w-full bg-gradient-to-b from-transparent via-60%  to-80% to-[rgba(0,0,0,0.8)]",
            // "w-full bg-gradient-to-b from-transparent  to-25% to-[oklch(var(--p)/.9)]",
            "absolute top-[66%] bottom-0 left-0 right-0 py-2 px-4 ",
            "text-primary-content"
          )}
        >
          <h2 className="drop-shadow card-title">{item.name}</h2>
          {location && (
            <p className={clsx("drop-shadow text-sm mb-2")}>
              <FontAwesomeIcon icon={faLocationDot} fixedWidth />
              {item.location}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}

function NewsFeedItemStatusReaction({
  item,
}: {
  item: News_Feed_Item_Status_Reaction_440Fragment;
}): JSX.Element {
  return (
    <ItemTextBox
      showQuotes
      headline={`Reacted with ${item.reaction} to your status update`}
      content={item.content}
    />
  );
}

function NewsFeedItemMedia({ item }: { item: News_Feed_Item_Media_440Fragment }): JSX.Element {
  const imgElement = React.useRef<HTMLImageElement | null>(null);
  const [tall, setTall] = React.useState(
    item.width && item.height ? item.height > item.width : false
  );
  return (
    <div className={clsx("NewsFeedItemMedia flex flex-col gap-1 ")}>
      <div
        className={classNames("w-full overflow-hidden relative", tall ? "" : "w-full max-h-[40vh]")}
        style={
          tall
            ? { height: "40vh" }
            : item.width && item.height
            ? { aspectRatio: `${item.width}/${item.height}` }
            : { minHeight: "40vh" }
        }
      >
        <LazyMediaItem
          className={classNames(tall ? "object-contain h-full " : "", " shadow-xl w-full ")}
          imgRef={imgElement}
          size="1280"
          thumbHash={item.thumb_hash}
          onLoad={(e) => {
            if (item.width && item.height) return;
            setTall((e?.currentTarget?.naturalHeight ?? 0) > (e?.currentTarget?.naturalWidth ?? 0));
          }} // print 150
          mediaUploadId={item.id}
        />
      </div>
      {item.caption && (
        <p className="px-2">
          <span className="font-bold pr-2">{item.owner_slug}</span>
          {item.caption}
        </p>
      )}
    </div>
  );
}

function NewsFeedItemEvent({ item }: { item: News_Feed_Item_Event_440Fragment }): JSX.Element {
  return (
    <div>
      <p>event</p>
      <p>{item.name}</p>
    </div>
  );
}

function NewsFeedItemStatus({ item }: { item: News_Feed_Item_Status_440Fragment }): JSX.Element {
  return <ItemTextBox showQuotes headline="Updated Status:" content={item.content} />;
}

export function NewsFeedItem({
  item,
  disableDeepLinks,
}: {
  item: NewsFeedItem;
  disableDeepLinks?: boolean;
}): JSX.Element {
  const reportConfirm = userReportConfirm();
  // const sent = React.useMemo(() => {
  //   const dt = DateTime.fromISO(item.created_at);
  //   const now = DateTime.now();
  //   return dt.toLocaleString(
  //     now.hasSame(dt, "day") ? DateTime.TIME_SIMPLE : DateTime.DATE_MED
  //   );
  // }, [item.created_at]);
  const slug = match(item)
    .with({ owner_slug: P.select() }, (p) => p)
    .with({ reactor_slug: P.select() }, (p) => p)
    .otherwise(() => null);
  const screenName = match(item)
    .with({ owner_screen_name: P.select() }, (p) => p)
    .with({ reactor_screen_name: P.select() }, (p) => p)
    .otherwise(() => null);
  if (!slug || !screenName) return <></>;

  const reportObj = match(item)
    .returnType<{ reportType: ReportType; reportItemId: string } | null>()
    .with({ __typename: "L_News_Feed_Item_Event" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Public_Event" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Media" }, () => ({
      reportType: "image",
      reportItemId: item.id,
    }))
    .with({ __typename: "L_News_Feed_Item_Media_Reaction" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Mention" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Message_Reaction" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Status" }, () => ({
      reportType: "status",
      reportItemId: item.id,
    }))
    .with({ __typename: "L_News_Feed_Item_Status_Reaction" }, () => null)
    .exhaustive();
  return (
    <DisableDeepLinksContext.Provider value={!!disableDeepLinks}>
      <DiscoveryItemWrapper
        item={item}
        className="w-full bg-primary-content py-2 gap-2 flex flex-col justify-start text-left"
      >
        <Link
          to={allRoutes.PROFILE.buildPath({ slug })}
          className="flex flex-row items-center justify-between gap-2 px-2"
        >
          <div className="flex flex-row items-center gap-2">
            <AvatarWithScreenName
              tailwindSize="8"
              slug={slug}
              screenName={screenName}
              caption={<DiscoveryItemSummary item={item} />}
            />
          </div>
          <EllipsisActions slug={slug} reportData={reportObj} />
        </Link>

        {match(item)
          .with({ __typename: "L_News_Feed_Item_Event" }, (item) => (
            <NewsFeedItemEvent item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Public_Event" }, (item) => (
            <NewsFeedItemPublicEvent item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Media" }, (item) => (
            <Link
              to={allRoutes.PROFILE.MEDIA_ITEM.buildPath({
                slug,
                media_upload_id: item.id,
              })}
              // preventScrollReset
            >
              <NewsFeedItemMedia item={item} />
            </Link>
          ))
          .with({ __typename: "L_News_Feed_Item_Media_Reaction" }, (item) => (
            <NewsFeedItemMediaReaction item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Mention" }, (item) => (
            <NewsFeedItemMention item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Message_Reaction" }, (item) => (
            <NewsFeedItemMessageReaction item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Status" }, (item) => (
            <NewsFeedItemStatus item={item} />
          ))
          .with({ __typename: "L_News_Feed_Item_Status_Reaction" }, (item) => (
            <NewsFeedItemStatusReaction item={item} />
          ))
          .exhaustive()}

        <p className="opacity-75 text-sm px-2 ">
          <RelativeDate dt={DateTime.fromISO(item.created_at)} />
        </p>
        {!item.__typename.match(/reaction/i) &&
          item.__typename !== "L_News_Feed_Item_Public_Event" && (
            <div className="flex flex-row justify-start gap-2 px-2 items-center">
              <AddReaction objectId={item.id} />
              <ReactionPills objectId={item.id} />
            </div>
          )}
      </DiscoveryItemWrapper>
    </DisableDeepLinksContext.Provider>
  );
}

function ItemTextBox({
  headline,
  content,
  showQuotes,
}: {
  showQuotes?: boolean;
  content: React.ReactNode;
  headline?: React.ReactNode;
}): JSX.Element {
  return (
    <div className="w-full">
      <div
        className="m-2   border-primary rounded-xl shadow-md p-5 flex flex-col"
        style={{ borderWidth: "1px" }}
      >
        {headline && <p className="text-xs">{headline}</p>}
        {showQuotes && (
          <p>
            <FontAwesomeIcon icon={faQuoteLeft} fixedWidth />
          </p>
        )}
        <p className="font-semibold">{content}</p>
        {showQuotes && (
          <p className="self-end">
            {" "}
            <FontAwesomeIcon icon={faQuoteRight} fixedWidth />
          </p>
        )}
      </div>
    </div>
  );
}

import React, { useState, useCallback } from "react";
import { Input } from "./input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/pro-solid-svg-icons";

interface SearchBarProps {
  onSearch: (term: string) => void;
  placeholder?: string;
  value: string;
}

export function SearchBar({
  onSearch,
  placeholder = "Search users...",
  value,
}: SearchBarProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    /* 
      Focus on input when value changes, this fixes a know 
      useDebounce issue clearing keyboard focus 
    */
    inputRef?.current?.focus();
  }, [value]);

  const handleSearch = (term: string) => {
    setLoading(true);
    onSearch(term);
    setLoading(false);
  };

  return (
    <div className="relative w-full mb-4">
      <div className="flex items-center bg-white rounded-md shadow-sm z-50">
        <Input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setLoading(true);
            onSearch(e.target.value);
            setLoading(false);
          }}
          className="w-full px-4 py-2 rounded-l-md focus:outline-none"
          ref={inputRef}
        />
        <div className="p-2 bg-gray-100 rounded-r-md">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="h-5 w-5 text-gray-500 animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} className="h-5 w-5 text-gray-500" />
          )}
        </div>
      </div>
    </div>
  );
}

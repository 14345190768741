import {
  Discovery_Success_300Fragment,
  Feature_Flags_Enum,
  GqlOps,
  L_Discovery_Response_Missing_Fields,
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useDiscoveryResults300Query,
  useHideDiscoveryResult300Mutation,
} from "shared/dist/__generated__/components";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Maybe, classNames, filterNulls, typedFromEntries, uniqWith } from "shared/dist/util";
import { P, match } from "ts-pattern";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { faSliders, faWarning, faXmark } from "@fortawesome/pro-solid-svg-icons";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Container } from "../../../widgets/container";
import { DatingSettings } from "../settings/dating/dating-main";
import { ErrorBoundary } from "react-error-boundary";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import { LazyMediaItem } from "../../../widgets/lazy-media-item";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { SpinnerButton } from "../../../widgets/spinner-button";
import { VerifiedIdentityIcon } from "../settings/identity";
import { VisibilityObserver } from "shared-web-react/dist/widgets/visibility-observer";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import discovery_zero_image_01 from "../../../assets/discovery_zero_image_1.jpg";
import discovery_zero_image_02 from "../../../assets/discovery_zero_image_2.jpg";
import discovery_zero_image_03 from "../../../assets/discovery_zero_image_3.jpg";
import discovery_zero_image_04 from "../../../assets/discovery_zero_image_4.jpg";
import discovery_zero_image_05 from "../../../assets/discovery_zero_image_5.jpg";
import discovery_zero_image_06 from "../../../assets/discovery_zero_image_6.jpg";
import discovery_zero_image_07 from "../../../assets/discovery_zero_image_7.jpg";
import discovery_zero_image_08 from "../../../assets/discovery_zero_image_8.jpg";
import discovery_zero_image_09 from "../../../assets/discovery_zero_image_9.jpg";
import discovery_zero_image_10 from "../../../assets/discovery_zero_image_10.jpg";
import discovery_zero_image_11 from "../../../assets/discovery_zero_image_11.jpg";
import discovery_zero_image_12 from "../../../assets/discovery_zero_image_12.jpg";
import { distToLocale } from "shared-web-react/dist/util/units";
import { faMessages } from "@fortawesome/pro-regular-svg-icons";
import { iconForRelationship } from "../../../widgets/add-relationship-button";
import { useGenderInfo } from "shared-web-react/dist/helpers/gender";
import { useIsProd } from "shared/dist/util/env";
import { useLeftMargin } from "../main-nav";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SearchBar } from "../../../widgets/search-bar";
import { ProfileList, ProfileListItem } from "../../../widgets/profile-list";
import { useMyInfo } from "../../bio/bio-settings";
import { useExtendedFriendList } from "../../../widgets/use-extended-friends-list";
import { useMyId } from "shared/dist/auth-data";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useDebounce } from "use-debounce";

function ProfileGridButtonContent({ icon }: Pick<FontAwesomeIconProps, "icon">): JSX.Element {
  return (
    <span
      className={classNames(
        "inline-flex font-bold justify-center items-center border-2 border-primary rounded-full bg-primary-content text-primary w-10 h-10"
      )}
    >
      <FontAwesomeIcon icon={icon} className="" />
    </span>
  );
}

type ProfileGridDummyDataProps = {
  isFake: true;
  fakeId: string;
  imgUrl: string;
};

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const zeroScreenData = [
  { gender_id: "gender_woman", screenName: "Jordan", image: discovery_zero_image_01 },
  { gender_id: "gender_woman", screenName: "Taylor", image: discovery_zero_image_02 },
  { gender_id: "gender_woman", screenName: "Casey", image: discovery_zero_image_03 },
  { gender_id: "gender_woman", screenName: "Alex", image: discovery_zero_image_04 },
  { gender_id: "gender_woman", screenName: "Morgan", image: discovery_zero_image_05 },
  { gender_id: "gender_woman", screenName: "Sam", image: discovery_zero_image_06 },
  { gender_id: "gender_woman", screenName: "Jamie", image: discovery_zero_image_07 },
  { gender_id: "gender_woman", screenName: "Cameron", image: discovery_zero_image_08 },
  { gender_id: "gender_woman", screenName: "Avery", image: discovery_zero_image_09 },
  { gender_id: "gender_woman", screenName: "Riley", image: discovery_zero_image_10 },
  { gender_id: "gender_woman", screenName: "Quinn", image: discovery_zero_image_11 },
  { gender_id: "gender_woman", screenName: "Peter", image: discovery_zero_image_12 },
] as const;
export const useZeroScreenDummyProfiles = () => {
  return zeroScreenData.map((data) => ({
    distance_m: getRandomNumber(1609, 50000),
    age: getRandomNumber(21, 50),
    ...data,
  }));
};

function GridGenderPill({ label }: { label: undefined | string }): null | JSX.Element {
  if (!label) return null;
  return (
    <div
      style={{ flexShrink: 1 }}
      className={classNames(
        "min-w-0 flex-grow-0 overflow-hidden lowercase whitespace-nowrap py-[1px] px-[4px] rounded-sm text-xs bg-secondary text-secondary-content text-ellipsis"
      )}
    >
      {label}
    </div>
  );
}

type ProfileGridRealDataProps = { id: string };
type ProfileGridItemProps = {
  age: number;
  distance_m: Maybe<number>;
  gender_id: string;
  limitations: Discovery_Success_300Fragment["limitations"];
  onBlock?: () => void;
  profileGridPageNumber?: number;
  relationships_to_user: Array<{ type: string; status: string }>;
  threads_with_user: Array<string>;
  user_screen_name: string;
  user_slug: string;
  verified: boolean;
  partner: Maybe<{
    user_slug: string;
    user_screen_name: string;
    gender_id: string;
  }>;
} & (ProfileGridDummyDataProps | ProfileGridRealDataProps);

function ProfileGridItem({
  age,
  distance_m,
  gender_id,
  limitations,
  onBlock,
  partner,
  profileGridPageNumber,
  relationships_to_user,
  threads_with_user,
  user_screen_name,
  user_slug,
  verified,
  ...props
}: ProfileGridItemProps): JSX.Element {
  const [hideMutation, { loading }] = useHideDiscoveryResult300Mutation();
  const isFake = "isFake" in props && props.isFake;
  const genderData = useGenderInfo(gender_id);
  const [isFading, setIsFading] = React.useState(false);
  const partnerGenderData = useGenderInfo(partner?.gender_id);
  const profileImageUrl = isFake ? { url: props.imgUrl } : useProfileImageUrl(user_slug);
  const navigate = useNavigate();
  const matchId = match(props)
    .with({ id: P.select() }, (i) => i)
    .otherwise(() => null);
  try {
    return (
      <div
        id={`disc_${user_slug}`}
        className={clsx(
          "ProfileGridItem relative w-full h-auto object-cover aspect-[3/4]",
          " rounded-lg overflow-hidden",
          isFading && "transition-opacity duration-500 opacity-0"
        )}
        onTransitionEnd={() => {
          if (!isFading) return;
          onBlock?.();
          hideMutation({
            variables: { slug: user_slug, partner_slug: partner?.user_slug, unhide: false },
            refetchQueries: [GqlOps.Query.DiscoveryResults300, GqlOps.Query.NewsFeed440],
            update: async (cache) => {
              const cacheId = cache.identify({
                __typename: "L_Discovery_Item",
                id: matchId,
              });
              cache.evict({ broadcast: true, id: cacheId });
            },
          });
        }}
      >
        <div
          className={`absolute inset-0 w-full h-full bg-cover bg-center ${
            isFake ? `blur-[8px]` : ""
          }`}
        >
          {"mediaUploadId" in profileImageUrl && profileImageUrl.mediaUploadId ? (
            <LazyMediaItem
              mediaUploadId={profileImageUrl.mediaUploadId}
              size="256"
              alt={user_screen_name}
              className="w-full h-full object-cover"
              overrideVisibility={true}
              thumbHash={profileImageUrl.thumbHash}
            />
          ) : (
            <LazyImage
              src={profileImageUrl.url}
              alt={user_screen_name}
              overrideVisibility
              className="w-full h-full object-cover"
            />
            //   <img
            //   src={profileImageUrl.url}
            //   alt={screen_name}
            //   className="w-full h-full object-cover"
            // />
          )}
        </div>
        <div
          onClick={() => {
            if (isFake) return;
            window.history.replaceState({ profileGridPageNumber, matchId }, "", location.pathname);
            navigate(allRoutes.PROFILE.buildPath({ slug: user_slug }));
          }}
          className="absolute inset-0 flex flex-col justify-end"
        >
          <div className="absolute opacity-80 top-2 left-2 right-2 flex flex-row gap-1 overflow-hidden items-center justify-between">
            <div className={clsx("flex flex-row gap-2 flex-grow-0 min-w-0")}>
              <GridGenderPill label={genderData?.data?.name?.en_US} />
              <GridGenderPill label={partnerGenderData?.data?.name?.en_US} />
            </div>
            {verified && <VerifiedIdentityIcon dark className={classNames("flex-0")} />}
          </div>
          {partner?.user_slug && (
            <div className={classNames("absolute left-2 bottom-16 right-[65%]")}>
              <Avatar
                tailwindSize="full"
                slug={partner.user_slug}
                className="border-2 border-white shadow-lg"
              />
            </div>
          )}
          <div
            className={classNames(
              "absolute right-2 max-w-[50%] overflow-x-hidden gap-1 flex flex-row justify-end items-center bottom-16"
            )}
          >
            {relationships_to_user.slice(0, 4).map((rel, idx) => (
              <FontAwesomeIcon
                key={idx}
                icon={iconForRelationship({
                  status: rel.status as Relationship_Statuses_Enum,
                  type: rel.type as Relationship_Types_Enum,
                })}
                className={classNames(
                  "flex-0 lowercase whitespace-nowrap p-1 rounded-sm text-xs bg-secondary text-secondary-content"
                )}
              />
            ))}

            {threads_with_user?.length ? (
              <FontAwesomeIcon
                icon={faMessages}
                className={classNames(
                  "flex-0 lowercase whitespace-nowrap p-1 rounded-sm text-xs bg-secondary text-secondary-content"
                )}
              />
            ) : null}
          </div>
          <div className="absolute left-0 bottom-0 right-0 bg-slate-500 rounded-b-lg p-2 bg-opacity-50 text-primary-content">
            <div className={classNames("flex-row flex gap-1 justify-between items-center")}>
              <div className="flex-col max-w-full overflow-hidden flex-1 flex text-xs ">
                <span className="font-bold overflow-hidden whitespace-nowrap text-ellipsis">
                  {user_screen_name}
                  {partner?.user_screen_name ? ` & ${partner.user_screen_name}` : ""}
                </span>
                {distance_m === 0 || distance_m ? (
                  <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                    {distToLocale({
                      dist: distance_m,
                      format: "short",
                      units: "m",
                    })}
                    , <span className="italic">{age}yo</span>
                  </span>
                ) : null}
              </div>
              <div className="flex-0 space-x-1 text-xl">
                <SpinnerButton
                  loading={loading}
                  disabled={isFake || loading || !matchId}
                  onClickWrapped={async (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    setIsFading(true);
                  }}
                >
                  <ProfileGridButtonContent icon={faXmark} />
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    console.error("🚀 ~ file: profile-grid.tsx:296 ~ err:", err);
    return <></>;
  }
}

function DiscoveryGridFilters({
  disabled,
  onClose,
}: {
  disabled?: boolean;
  onClose: () => void;
}): JSX.Element {
  // const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDialogElement>(null);
  // console.log("🚀 - file: profile-grid.tsx:315 - ref:", ref?.current?.open);
  const toggleFilters = React.useCallback(async () => {
    if (disabled) return;
    const isOpen = !!ref?.current?.open;
    console.log("🚀 - file: profile-grid.tsx:319 - toggleFilters - isOpen:", isOpen);
    isOpen ? ref?.current?.close?.() : ref?.current?.showModal?.();
    if (isOpen) onClose();
  }, [disabled, ref]);
  return (
    <>
      <button
        onClick={toggleFilters}
        disabled={disabled}
        className="max-w-full flex flex-row flex-nowrap items-center justify-start overflow-x-hidden"
      >
        <div
          className={classNames(
            "flex-0 rounded-md p-1 border-secondary w-9 h-9 text-center text-secondary aspect-square border-2"
          )}
        >
          <FontAwesomeIcon icon={faSliders} fixedWidth />
        </div>
        <div
          className={classNames(
            "flex-1 min-w-0 ml-2 whitespace-nowrap text-ellipsis overflow-hidden"
          )}
        >
          {/* <DatingPreferencesSummary /> */}
        </div>
      </button>
      <PortalDialog ref={ref} onCancel={toggleFilters} modalBoxClassName="p-0 ">
        <div className={"px-2 py-4"}>
          <DatingSettings />
        </div>
        <button
          className="https://bio-staging.trustcandidstaging.com/_mi/90bdb8cd-7b92-46e9-9985-d26a6cff83ae?size=1280btn-neutral btn-circle absolute top-2 right-2"
          onClick={toggleFilters}
        >
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </button>
      </PortalDialog>
    </>
  );
}

function DiscoveryError({ explanation }: { explanation: string }): JSX.Element {
  return (
    <DiscoveryModal color="error">
      <FontAwesomeIcon icon={faWarning} size="6x" />
      <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        But we seem to having some errors. We are looking into it and apologize.
      </p>
      <p>{explanation}</p>
    </DiscoveryModal>
  );
}
function DiscoveryEmpty({}: {}): JSX.Element {
  return (
    <DiscoveryModal color="neutral">
      <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        Unfortunately we just don't have anyone matching your preferences quite yet. Try adjusting
        your filters here:
      </p>
      <Link className="btn btn-primary" to={allRoutes.SETTINGS.DATING.buildPath({})}>
        Dating Preferences
      </Link>
    </DiscoveryModal>
  );
}

function DiscoveryComingSoon({}: {}): JSX.Element {
  return (
    <DiscoveryModal color="neutral">
      <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        We are slowly inviting our beloved users into discovery to ensure the best possible
        experience. We'll let you know when we're ready for you.
      </p>
    </DiscoveryModal>
  );
}

function DiscoveryModal({
  children,
  color,
}: React.PropsWithChildren<{
  color: "secondary" | "warning" | "error" | "neutral" | "accent";
}>): JSX.Element {
  const marginLeft = useLeftMargin();
  return (
    <div className={classNames("relative max-h-screen overflow-hidden p-2")}>
      <DiscoveryGridDummyData />
      <div
        className={classNames(
          "fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center p-6"
        )}
        style={{ marginLeft }}
      >
        <div
          className={classNames(
            `rounded-lg  bg-${color} opacity-95 text-${color}-content shadow-md w-full  max-w-3xl max-h-full overflow-y-auto overflow-x-hidden flex flex-col items-center justify-center gap-2 p-6`
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

const MissingFields = L_Discovery_Response_Missing_Fields;
function DiscoveryNotReadyReason({
  field,
}: {
  field: L_Discovery_Response_Missing_Fields;
}): JSX.Element {
  const [reason, link] = React.useMemo(
    () =>
      match(field)
        .returnType<[string, string]>()
        .with(MissingFields.Birthday, () => ["Birthday", allRoutes.SETTINGS.PERSONAL.buildPath({})])
        .with(MissingFields.Gender, () => ["Gender", allRoutes.SETTINGS.PROFILE.buildPath({})])
        .with(MissingFields.Location, () => ["Location", allRoutes.SETTINGS.PROFILE.buildPath({})])
        .with(MissingFields.DiscoveryPreferences, () => [
          "Dating Preferences",
          allRoutes.SETTINGS.DATING.buildPath({}),
        ])
        .exhaustive(),
    [field]
  );
  return (
    <Link className="link" to={link}>
      {reason}
    </Link>
  );
}

function DiscoveryNotReady({
  fields,
}: {
  fields: Array<L_Discovery_Response_Missing_Fields>;
}): JSX.Element {
  return (
    <DiscoveryModal color="warning">
      <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        Unfortunately your profile isn't ready to partake in this amazing goodness.
      </p>
      <p className={classNames("text-lg")}>You'll have to set the following to be ready:</p>
      <ul className={classNames("list-disc w-full list-outside ml-8")}>
        {fields.map((field) => (
          <li key={field}>
            <DiscoveryNotReadyReason field={field} />
          </li>
        ))}
      </ul>
    </DiscoveryModal>
  );
}

export function DiscoveryMain({
  override,
}: {
  override?: "empty" | "not_ready" | "error" | "dummy_data" | "waitlist";
}): JSX.Element {
  const isProd = useIsProd();
  const navigate = useNavigate();
  const { slug } = useMyInfo();
  const my_id = useMyId();
  const [page, setPage] = React.useState<number>(window.history.state.profileGridPageNumber ?? 0);
  const [fetchedAllResults, setFetchedAllResults] = React.useState(false);
  const [loadedItems, setLoadedItems] = React.useState<
    Array<Discovery_Success_300Fragment["items"][0]>
  >([]);

  const [itemPageNumbers, setItemPageNumbers] = React.useState<Record<string, number>>({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const extendedFriendsFeatureFlag = useIsFeatureEnabled(
    Feature_Flags_Enum.ExtendedFriendSearchV00
  );
  const [debouncedFilter, { isPending }] = useDebounce(searchTerm, 1000);

  const [cachedProfiles, setCachedProfiles] = React.useState<ProfileListItem[]>([]);

  const incPage = React.useCallback(() => setPage((page) => page + 1), [setPage]);

  React.useEffect(() => {
    setFetchedAllResults(false);
    setLoadedItems([]);
  }, [override]);

  const { data, loading, fetchMore, refetch, client } = useDiscoveryResults300Query({
    variables: {
      use_dummy_data: !isProd && override === "dummy_data",
      page,
    },
    fetchPolicy: "cache-first",
  });

  const { data: extendedFriendListData, loading: extendedFriendListLoading } =
    useExtendedFriendList({
      slug: slug!,
      userId: my_id!,
      includeRelationships: true,
      filter: debouncedFilter,
    });

  const handleSearch = React.useCallback((term: string) => {
    setSearchTerm(term);
    setLoadedItems([]);
    setPage(0);
    setFetchedAllResults(false);
  }, []);

  React.useEffect(() => {
    if (data?.l_discovery?.__typename === "L_Discovery_Response_Success" && !searchTerm) {
      const newItems = data.l_discovery.items;
      if (newItems?.length === 0) {
        setFetchedAllResults(true);
        return;
      }
      setItemPageNumbers((current) => ({
        ...current,
        ...typedFromEntries(newItems.map((i) => [i.id, page])),
      }));
      setLoadedItems((currentItems) =>
        uniqWith(filterNulls([...currentItems, ...newItems]), (a, b) => a.id === b.id)
      );
    }
  }, [data, page, searchTerm]);

  const discData = data?.l_discovery;

  const filteredProfiles = React.useMemo(() => {
    if (!extendedFriendListData || !searchTerm) return [];

    const result = extendedFriendListData
      .map((item) => ({
        id: item.id,
        slug: item.user.user_slug,
        name: item.user.user_screen_name,
        screenName: item.user.user_screen_name,
      }))
      .filter((item) => item.screenName.toLowerCase().startsWith(searchTerm.toLowerCase()));

    if (!isPending()) {
      setCachedProfiles(result);
    }

    return result;
  }, [extendedFriendListData, searchTerm, isPending]);

  const profilesToDisplay = isPending() ? cachedProfiles : filteredProfiles;

  const limitations = discData && "limitations" in discData ? discData.limitations : [];
  if (override && isProd) navigate(allRoutes.DISCOVERY.buildPath({}));
  const filtersDisabled = match({ loading, override, responseType: discData?.__typename })
    .with(
      { override: P.nullish, responseType: "L_Discovery_Response_Success" },
      { override: P.nullish, responseType: "L_Discovery_Response_Empty" },
      () => false
    )
    .otherwise(() => true);

  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const rowCount = 1 + (loadedItems?.length ?? 0);
  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => scrollParentRef.current,
    estimateSize: () => 330,
    lanes: 2,
    getItemKey: (index) => loadedItems?.[index]?.id ?? "<empty>",
    gap: 8,
    overscan: 8 /* cSpell:disable-line */,
  });

  const { log: onScreenLogger, clear } = useOnScreenLogger();
  React.useEffect(() => {
    onScreenLogger({
      content: `page: ${page} items ${loadedItems?.length}`,
      id: "disc_page",
    });
    return () => clear();
  }, [page, loadedItems?.length, clear, onScreenLogger]);

  React.useEffect(() => {
    onScreenLogger({
      content: `rowcount: ${rowCount} items: ${loadedItems?.length}`,
      id: "rowcount",
    });
    onScreenLogger({ content: "resp: " + discData?.__typename, id: "tn" });
  }, [rowCount, discData, loadedItems?.length, onScreenLogger]);

  const onAtBottom = React.useCallback(
    async (atBottom: boolean) => {
      if (filtersDisabled || fetchedAllResults || loading || searchTerm !== "") return;
      if (!atBottom) return;

      fetchMore({
        variables: {
          use_dummy_data: !isProd && override === "dummy_data",
          page: page + 1,
          filter: debouncedFilter,
        },
      });
      incPage();
    },
    [
      fetchedAllResults,
      loading,
      fetchMore,
      page,
      incPage,
      filtersDisabled,
      searchTerm,
      isProd,
      override,
      debouncedFilter,
    ]
  );

  React.useEffect(() => {
    if (!loadedItems || loadedItems.length === 0) return;
    const elementId = window.history.state?.matchId;
    if (!elementId) return;
    const index = loadedItems.findIndex((i) => i.id === elementId);
    if (elementId) rowVirtualizer.scrollToIndex(index);
    window.history.replaceState({}, "");
  }, [loadedItems, rowVirtualizer]);

  return (
    <Container
      size="xs"
      className={classNames(
        "DiscoveryMainContainer h-full flex-col items-stretch justify-start relative overflow-hidden flex"
      )}
    >
      <div className="absolute inset-0 h-full px-1">
        <div className="flex z-20 relative">
          <DiscoveryFiltersWidget
            disabled={filtersDisabled}
            loading={loading}
            onFilterClose={async () => {
              setPage(0);
              setFetchedAllResults(false);
              setLoadedItems([]);
              client.cache.evict({ fieldName: "l_discovery" });
              client.cache.evict({ fieldName: "l_news_feed" });
              refetch();
            }}
          />
          {extendedFriendsFeatureFlag && (
            <SearchBar
              onSearch={handleSearch}
              placeholder="Search connections..."
              value={searchTerm}
            />
          )}
        </div>
        <div
          ref={scrollParentRef}
          className="flex-1 relative h-full w-full max-h-full overflow-auto pb-16"
        >
          {searchTerm ? (
            extendedFriendListLoading ? (
              <SpinnerCentered />
            ) : profilesToDisplay.length > 0 ? (
              <ProfileList
                profiles={profilesToDisplay}
                mkRoute={(profile) => ({
                  to: allRoutes.PROFILE.buildPath({ slug: profile.slug! }),
                })}
                hasBadge={() => false}
              />
            ) : (
              <p>No results available </p>
            )
          ) : (
            match({ loading, override, loadedItems, response: discData })
              .returnType<React.ReactNode>()
              .with({ loading: true, loadedItems: [] }, () => <SpinnerCentered />)
              .with(
                { response: { __typename: "L_Discovery_Response_Waitlist" } },
                { override: "waitlist" },
                () => <DiscoveryComingSoon />
              )
              .with(
                { response: { __typename: "L_Discovery_Response_Empty" }, loadedItems: [] },
                { override: "empty" },
                () => <DiscoveryEmpty />
              )
              .with(
                { response: { __typename: "L_Discovery_Response_Error" } },
                { override: "error" },
                () => <DiscoveryError explanation="Oops!" />
              )
              .with({ override: "not_ready" }, () => (
                <DiscoveryNotReady
                  fields={[
                    MissingFields.DiscoveryPreferences,
                    MissingFields.Gender,
                    MissingFields.Birthday,
                  ]}
                />
              ))
              .with(
                { response: { __typename: "L_Discovery_Response_Not_Ready_Yet" } },
                (missingData) => <DiscoveryNotReady fields={missingData.response.missing_fields} />
              )
              .with({ loadedItems: P.select() }, (items) => (
                <div
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    <ErrorBoundary FallbackComponent={GridFallback}>
                      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const isLeft = virtualRow.index % 2 === 0;
                        const neighborRow =
                          rowVirtualizer.getVirtualItems()[virtualRow.index + (isLeft ? 1 : -1)];
                        const match = items[virtualRow.index];
                        return (
                          <div
                            className={clsx(
                              "py-1",
                              isLeft ? "pr-1 pg-left-side" : "pl-1 pg-right-side",
                              "pg-row-idx-" + virtualRow.index,
                              `pg-start-${virtualRow.start}-neighbor-${
                                neighborRow?.start ?? "missing"
                              }`
                            )}
                            key={virtualRow.key}
                            style={{
                              position: "absolute",
                              top: 0,
                              width: "50%",
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${Math.max(virtualRow.start)}px)`,
                              ...(isLeft ? { left: 0 } : { right: 0 }),
                            }}
                          >
                            <div data-index={virtualRow.index} ref={rowVirtualizer.measureElement}>
                              {match && (
                                <ProfileGridItem
                                  {...match.user}
                                  {...{ id: match.id, verified: !!match.user.verified }}
                                  profileGridPageNumber={itemPageNumbers[match.id]}
                                  onBlock={() =>
                                    setLoadedItems((items) =>
                                      items.filter((i) => i.id !== match.id)
                                    )
                                  }
                                  {...{ partner: match.partner }}
                                  relationships_to_user={match.user.relationships_with_user}
                                  limitations={limitations}
                                />
                              )}
                              {virtualRow.index === rowCount - 1 && (
                                <VisibilityObserver
                                  onVisibility={onAtBottom}
                                  className="h-2 w-full"
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </ErrorBoundary>
                  </div>
                </div>
              ))
              .exhaustive()
          )}
        </div>
      </div>
    </Container>
  );
}

function GridFallback(): JSX.Element {
  return (
    <div className="w-full h-full flex-col-center-center bg-primary">
      <Spinner />
    </div>
  );
}

function DiscoveryFiltersWidget({
  disabled,
  loading,
  onFilterClose,
}: {
  loading: boolean;
  disabled: boolean;
  onFilterClose: () => void;
}): JSX.Element {
  return (
    <div
      className={classNames(
        "DiscoveryFiltersWidget h-full flex flex-col justify-stretch items-stretch max-h-full overflow-hidden "
      )}
    >
      <div
        className={classNames(
          "z-20-main-ui-overlay",
          "backdrop-blur-sm",
          "flex-0 fit-content flex items-center justify-stretch py-2 "
        )}
        style={{ background: `rgba(255,255,255,0.75)` }}
      >
        <DiscoveryGridFilters disabled={disabled} onClose={onFilterClose} />
        {loading && <Spinner className="absolute right-1 " />}
      </div>
    </div>
  );
}

type Matches = Discovery_Success_300Fragment["items"];

function DiscoveryGridDummyData(): JSX.Element {
  const dummyProfileData = useZeroScreenDummyProfiles();
  const zeroScreenDummyData: Matches = React.useMemo(
    () =>
      dummyProfileData.map(({ screenName, ...profile }) => ({
        __typename: "L_Discovery_Item",
        id: "",
        user: {
          __typename: "L_Discovery_User",
          threads_with_user: [],
          relationships_with_user: [],
          user_slug: screenName,
          user_screen_name: screenName,
          ...profile,
        },
        partner: undefined,
      })),
    []
  );
  return (
    <div className={classNames("flex-1 my-2 pb-16")}>
      <div className="grid grid-cols-2 gap-2">
        {zeroScreenDummyData.map(
          (match, index) =>
            match.user && (
              <ProfileGridItem
                id={index.toString()}
                key={index.toString()}
                {...match.user}
                {...{ partner: match.partner }}
                threads_with_user={[]}
                relationships_to_user={[]}
                limitations={[]}
                imgUrl={dummyProfileData[index].image}
                verified={Math.random() > 0.5}
              />
            )
        )}
      </div>
    </div>
  );
}
